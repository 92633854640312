.logo {
  height: 40px;
}

h2 {
  font-size: 32px;
  text-transform: uppercase;
}

p {
  font-size: 20px;
  font-weight: 600;
  color: rgb(45, 125, 128);
}

@media (max-width: 490px) {
  .logo {
    height: 35px;
  }
  h2 {
    font-size: 26px;
  }
  p {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 16px;
  }
}
