.game-container {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.game-container.visible {
  opacity: 1;
  transform: scale(1);
}

h4 {
  margin-left: 15px;
  font-weight: 600;
  color: rgb(45, 125, 128);
}

.back {
  box-shadow: -2px 3px 1px 0px rgba(150, 147, 147, 0.75);
}
