.card {
  position: relative;
  cursor: pointer;
}

.card img {
  height: 100px;
  width: 100px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: -2px 3px 1px 0px rgba(150, 147, 147, 0.75);
  border: 1px solid #7dd2f3;
}

.card .front {
  transform: rotateY(90deg);
  transition: all ease-in 0.1s;
  position: absolute;
}

.flipped .front {
  transform: rotateY(0deg);
  transition-delay: 0.1s;
}

.card-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  column-gap: 0px;
  row-gap: 10px;
  width: 450px;
  margin: auto;
}

.card .back {
  transition: all ease-in 0.1s;
  transition-delay: 0.1s;
}

.flipped .back {
  transform: rotateY(90deg);
  transition-delay: 0s;
}

@media (max-width: 1200px) {
  .card img {
    height: 75px;
    width: 75px;
    border-radius: 8px;
  }
  .card-grid {
    margin-top: 40px;
    width: 370px;
    margin: auto;
  }
}

@media (max-width: 500px) {
  .card-grid {
    margin-top: 40px;
    width: 300px;
    margin: auto;
  }
  .card img {
    height: 60px;
    width: 60px;
    border-radius: 5px;
  }
  .moves,
  .timer {
    font-size: 16px;
  }
  .hourGlass {
    width: 20px;
  }
}

@media (max-width: 400px) {
  .card img {
    height: 60px;
    width: 60px;
  }
  .card-grid {
    margin-top: 40px;
    width: 280px;
    margin: auto;
  }
}
