.result-container {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.result-container.visible {
  opacity: 1;
  transform: scale(1);
}
