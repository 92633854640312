.description {
  width: 60%;
}

.title {
  color: #d1feff;
  text-shadow: 2px 2px 2px rgba(51, 50, 51, 0.5);
  font-weight: bold;
}

.homeButton {
  background-color: rgb(61, 166, 170);
  color: #d1feff;
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 30px;
  width: 170px;
  height: 50px;
  cursor: pointer;
  box-shadow: -2px 3px 1px 0px rgba(150, 147, 147, 0.75);
  text-transform: uppercase;
  position: relative;
  bottom: 70px;
}

.homeButton:hover {
  transform: translateY(2%);
}

.canvas {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  width: auto;
}

.row {
  background-image: url(../assets/puzzle-tile.png);
  background-position: center;
  background-size: cover;
  margin: 5px;
  border-radius: 8px;
  box-shadow: -2px 3px 1px 0px rgba(150, 147, 147, 0.75);
  cursor: pointer;
  border: 1px solid #7dd2f3;
}

@media (max-width: 1080px) {
  .homeButton {
    bottom: 0px;
    top: 30px;
  }
  .description {
    width: auto;
  }
}

@media (max-width: 650px) {
  .homeButton {
    font-size: 16px;
  }
}
